// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-to-js": () => import("./../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-order-js": () => import("./../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-our-story-js": () => import("./../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-products-detergent-js": () => import("./../src/pages/products/detergent.js" /* webpackChunkName: "component---src-pages-products-detergent-js" */),
  "component---src-pages-products-disinfectant-js": () => import("./../src/pages/products/disinfectant.js" /* webpackChunkName: "component---src-pages-products-disinfectant-js" */),
  "component---src-pages-products-fourth-js": () => import("./../src/pages/products/fourth.js" /* webpackChunkName: "component---src-pages-products-fourth-js" */),
  "component---src-pages-products-hand-sanitizer-js": () => import("./../src/pages/products/hand-sanitizer.js" /* webpackChunkName: "component---src-pages-products-hand-sanitizer-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-technology-js": () => import("./../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

